<template>
  <div>
    <div class="es_shadow" v-if="isShow">
      <div class="es_shadow-link"></div>
      <div class="es_shadow-btn" @click="tryToParent"></div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    tryToParent() {
      this.$emit("toParent",false)
    },
  },
}
</script>
<style lang='less' scoped>
.es_shadow{
  position: fixed;
  background-color: rgba(0,0,0,.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999;
}
.es_shadow-link{
	position: absolute;
	right: 3%;
	top: 3%;
	width: 5.55rem;
	height: 3.14rem;
  background-image: url(../../assets/images/img/share-guide.png);
  background-size: 100% 100%;
}
.es_shadow-btn{
	position: absolute;
  top: 45%;
  left: 50%;
  transform: translateX(-50%);
	width:3.21rem;
  height: 1.3rem;
  background-size: 100% 100%;
  background-image: url(../../assets/images/img/i-hnow-btn-bg.png);
  
}
</style>